/* eslint-disable arrow-parens */
/* eslint-disable semi */
/* eslint-disable quotes */
import store from "@/store";
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      name: "dashboard",
      component: () => import("@/views/dashboard/Dashboard.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Dashboard",
        breadcrumb: [
          {
            text: "Dashboard",
            active: true,
          },
        ],
      },
    },
    {
      path: "/course/sales/report",
      name: "course-sales-report",
      component: () => import("@/views/report/SalesReport.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Course Wise Sales Over View",
        breadcrumb: [
          {
            text: "Report",
            active: true,
          },
        ],
      },
    },
    {
      path: "/teacher/sales/report",
      name: "teacher-sales-report",
      component: () => import("@/views/report/TeacherReport.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Teacher Wise Sales Over View",
        breadcrumb: [
          {
            text: "Report",
            active: true,
          },
        ],
      },
    },
    {
      path: "/course/completion/report",
      name: "courseCompletion",
      component: () => import("@/views/report/CourseCompletion.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Course Completion",
        breadcrumb: [
          {
            text: "Course Completion",
            active: true,
          },
        ],
      },
    },
    {
      path: "/home",
      name: "home",
      component: () => import("@/views/cms/home/index.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Home",
        breadcrumb: [
          {
            text: "Banner",
            active: true,
          },
        ],
      },
    },
    {
      path: "/about",
      name: "about",
      component: () => import("@/views/cms/about/About.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "About",
        breadcrumb: [
          {
            text: "About",
            active: true,
          },
        ],
      },
    },
    {
      path: "/certification",
      name: "certification",
      component: () => import("@/views/cms/certificate/index.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Certification",
        breadcrumb: [
          {
            text: "Certification",
            active: true,
          },
        ],
      },
    },
    {
      path: "/studentFeedback",
      name: "studentFeedback",
      component: () => import("@/views/cms/studentFeedback/index.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Student Feedback",
        breadcrumb: [
          {
            text: "Student Feedback",
            active: true,
          },
        ],
      },
    },

    {
      path: "/faq",
      name: "faq",
      component: () => import("@/views/cms/faq/faq.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "FAQ",
        breadcrumb: [
          {
            text: "FAQ",
            active: true,
          },
        ],
      },
    },
    {
      path: "/popUp",
      name: "popUp",
      component: () => import("@/views/cms/popup/PopUp.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "PopUp Banner",
        breadcrumb: [
          {
            text: "PopUp Banner",
            active: true,
          },
        ],
      },
    },
    {
      path: "/contactUs",
      name: "contactUs",
      component: () => import("@/views/cms/contactUs/contactUs.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Contact Us",
        breadcrumb: [
          {
            text: "Contact Us",
            active: true,
          },
        ],
      },
    },
    {
      path: "/privacyPolicy",
      name: "privacyPolicy",
      component: () => import("@/views/cms/privacyPolicy/privacyPolicy.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Privacy Policy",
        breadcrumb: [
          {
            text: "Privacy Policy",
            active: true,
          },
        ],
      },
    },
    {
      path: "/terms",
      name: "terms",
      component: () => import("@/views/cms/terms/terms.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Terms Of Use",
        breadcrumb: [
          {
            text: "Terms Of Use",
            active: true,
          },
        ],
      },
    },
    {
      path: "/discount/leads",
      name: "discountLeads",
      component: () => import("@/views/cms/discountLeads/DiscountLeads.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Discount Leads",
        breadcrumb: [
          {
            text: "Discount Leads",
            active: true,
          },
        ],
      },
    },
    {
      path: "/footer",
      name: "footer",
      component: () => import("@/views/cms/footer/footer.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Footer",
        breadcrumb: [
          {
            text: "Footer",
            active: true,
          },
        ],
      },
    },
    {
      path: "/option",
      name: "option",
      component: () => import("@/views/cms/option/option.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Option",
        breadcrumb: [
          {
            text: "Option",
            active: true,
          },
        ],
      },
    },
    {
      path: "/category",
      name: "category",
      component: () => import("@/views/category/Manage.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Category",
        breadcrumb: [
          {
            text: "Category",
            active: true,
          },
        ],
      },
    },
    {
      path: "/course",
      name: "course",
      component: () => import("@/views/course/Index.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Course",
        breadcrumb: [
          {
            text: "Course",
            active: true,
          },
        ],
      },
    },
    {
      path: "/course/:id",
      name: "course",
      component: () => import("@/views/course/Manage.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Course Manage",
        breadcrumb: [
          {
            text: "Course",
            to: "/course",
          },
          {
            text: "Manage",
            active: true,
          },
        ],
      },
    },
    {
      path: "/option",
      name: "options",
      component: () => import("@/views/course/partials/teacher.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Options Manage",
        breadcrumb: [
          {
            text: "Options Manage",
            active: true,
          },
        ],
      },
    },
    {
      path: "/partner",
      name: "partner",
      component: () => import("@/views/cms/partner/partner.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Partner Manage",
        breadcrumb: [
          {
            text: "Partner",
            active: true,
          },
        ],
      },
    },
    {
      path: "/roles",
      name: "roles",
      component: () => import("@/views/role/Manage.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Role Manage",
        breadcrumb: [
          {
            text: "Role Manage",
            active: true,
          },
        ],
      },
    },
    {
      path: "/teacher",
      name: "teacher",
      component: () => import("@/views/teacher/Manage.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Teacher Manage",
        breadcrumb: [
          {
            text: "Teacher Manage",
            active: true,
          },
        ],
      },
    },
    {
      path: "/roles/create",
      name: "roles",
      component: () => import("@/views/role/Create.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Role Create",
        breadcrumb: [
          {
            text: "Role Create",
            active: true,
          },
        ],
      },
    },
    {
      path: "/roles/edit/:id",
      name: "roles",
      component: () => import("@/views/role/Edit.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Role Edit",
        breadcrumb: [
          {
            text: "Role Edit",
            active: true,
          },
        ],
      },
    },
    {
      path: "/users",
      name: "users",
      component: () => import("@/views/user/Manage.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "User Manage",
        breadcrumb: [
          {
            text: "User Manage",
            active: true,
          },
        ],
      },
    },
    {
      path: "/users/create",
      name: "users",
      component: () => import("@/views/user/Create.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "User Create",
        breadcrumb: [
          {
            text: "User Create",
            active: true,
          },
        ],
      },
    },
    {
      path: "/users/edit/:id",
      name: "users",
      component: () => import("@/views/user/Edit.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "User Edit",
        breadcrumb: [
          {
            text: "User Edit",
            active: true,
          },
        ],
      },
    },
    {
      path: "/vouchers",
      name: "vouchers",
      component: () => import("@/views/voucher/Index.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Voucher",
        breadcrumb: [
          {
            text: "Voucher",
            active: true,
          },
        ],
      },
    },
    {
      path: "/vouchers/create",
      name: "vouchers",
      component: () => import("@/views/voucher/Create.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Voucher Create",
        breadcrumb: [
          {
            text: "Vouchers",
            to: "/vouchers",
          },
          {
            text: "Create",
            active: true,
          },
        ],
      },
    },
    {
      path: "/vouchers/edit/:id",
      name: "vouchers",
      component: () => import("@/views/voucher/Edit.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Voucher Update",
        breadcrumb: [
          {
            text: "Vouchers",
            to: "/vouchers",
          },
          {
            text: "Update",
            active: true,
          },
        ],
      },
    },
    {
      path: "/students",
      name: "students",
      component: () => import("@/views/student/StudentList.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Student Lists",
        breadcrumb: [
          {
            text: "Students",
            active: true,
          },
        ],
      },
    },
    {
      path: "/tickets",
      name: "tickets",
      component: () => import("@/views/student/SupportTickets.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Support Ticket",
        breadcrumb: [
          {
            text: "Tickets",
            active: true,
          },
        ],
      },
    },
    {
      path: "/problem/solve",
      name: "problem-solve",
      component: () => import("@/views/problem/Manage.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Student Issue Solve",
        breadcrumb: [
          {
            text: "Tickets",
            active: true,
          },
        ],
      },
    },
    {
      path: "/purchases",
      name: "purchases",
      component: () => import("@/views/purchase/PurchaseList.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Purchases Lists",
        breadcrumb: [
          {
            text: "Students",
            active: true,
          },
        ],
      },
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/Login.vue"),
      meta: {
        visitor: true,
        layout: "full",
      },
    },
    {
      path: "/Qf4miKLzvnqCPqs8PrlMHA69JJSw6g1P/:coupon",
      name: "coupon",
      component: () => import("@/views/public_dashboard/Dashboard.vue"),
      meta: {
        visitor: true,
        layout: "full",
      },
    },
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        visitor: true,
        layout: "full",
      },
    },
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  if (localStorage.getItem("token")) {
    store.dispatch("CheckAuth");
  }
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiredAuth)) {
    if (!store.getters.loggedIn) {
      next({ name: "login" });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.visitor)) {
    if (store.getters.loggedIn) {
      next({ name: "dashboard" });
    } else {
      next();
    }
  }
});

export default router;
